<template>
    <div></div>
</template>

<script>
    "use strict";

    import firebase from "@/mixins/firebase.js";

    export default {
        "mixins": [
            firebase,
        ],
        "props": {
            "name": {
                "type": String,
                "required": true,
            },
        },
        created() {
            this.signOut();
        },
        "methods": {
            signOut() {
                this.$_signOut().then(() => {
                    this.$router.push({
                        "name": `/${this.name}`,
                    });
                }).catch(error => {
                    alert(error.message);
                });
            },
        },
    };
</script>
